('use strict');
import { Component, Inject, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { ReversePipe } from 'ngx-pipes';

import { SubTheme, Theme } from 'src/app/models/Theme';
import { Indicator } from 'src/app/models/Indicator';
import { TerritoryLabel, TerritoryScale, Territory } from 'src/app/models/TerritoryTypes';

import { LocalStorageService } from 'src/app/services/local-storage.service';
import { RestService } from 'src/app/services/RestService';
import { TerService } from 'src/app/services/TerService';

import { ROLE } from 'src/app/shared/enums/role.enum';

@Component({
    selector: 'updateUser',
    templateUrl: './user-update.template.html',
    providers: [ReversePipe],
})
export class UpdateUserComponent implements OnInit {
    public userId = null;

    public isUserSuperAdmin = this.localStorageService.get('user').role == ROLE.SUPER_ADMIN;
    public generalInfoTmp: { surname: string; name: string; mail: string; login: string };
    public user: { generalInfo: any; territory: any; indicatorIds: any; roleId: any; groupId: any };
    public groups = [];
    public territoryScales = [];
    public selectedTerritoryScale: any['type'] = [];
    public selectedTerritories = [];
    public selectedGranularity: any['type'] = [];
    public territoryLabels = {};
    public indicatorsByTheme = [];
    public territories = [];
    public granularities = [];
    public selectedIndicatorIds = [];
    public _nomFG = null;
    public territory = null;

    public roles = [
        {
            id: 1,
            label: 'Super Administrateur',
        },
        {
            id: 2,
            label: 'Administrateur local',
        },
        {
            id: 3,
            label: 'Utilisateur',
        },
    ];

    public rolesOption = this.roles;

    // public generalInfoManagement: { isEditing: boolean; isSaving: boolean; isDisabled: boolean };
    public generalInfoManagement = {
        isEditing: false,
        isSaving: false,
        isDisabled: false,
    };

    // public generalInfo: { surname: string; name: string; mail: any; login: any };
    public generalInfo = { surname: '', name: '', mail: null, login: null };

    // public territoryManagement: { isEditing: boolean; isSaving: boolean; isDisabled: boolean };
    public territoryManagement = {
        isEditing: false,
        isSaving: false,
        isDisabled: false,
    };

    // public roleGroupManagement: { isEditing: boolean; isSaving: boolean; isDisabled: boolean };
    public roleGroupManagement = {
        isEditing: false,
        isSaving: false,
        isDisabled: false,
    };

    // public selectedRole: { id: number; label: string };
    public selectedRole = { id: null, label: '' };

    // public selectedGroup: { id: any; indicators: any; territory: any; group: any };
    public selectedGroup = { id: null, indicators: null, territory: null, group: null };

    // public indicatorsManagement: { isEditing: boolean; isSaving: boolean; isDisabled: boolean };
    public indicatorsManagement = {
        isEditing: false,
        isSaving: false,
        isDisabled: false,
    };

    public isEditingAllow: boolean = false;

    constructor(
        private notification: ToastrService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        @Inject(LocalStorageService) private localStorageService: LocalStorageService,
        @Inject(TerService) private terService: TerService,
        @Inject(RestService) private restService: RestService,
    ) {}

    async ngOnInit(): Promise<void> {
        const currentUser = this.localStorageService.get('user');
        this.activatedRoute.data.subscribe(({ user }) => {
            this.user = user;
            this.userId = user.id;

            this.isEditingAllow = currentUser.role <= this.user.roleId;
        });

        this.initMain();
    }

    async initMain() {
        try {
            await Promise.all([this.initGroupsInfo(), this.initTerritoryScales()]);
            this.initGeneralInfo();
            this.initRoleGroup();
            this.initTerritory();
            this.initIndicators();
            await this.updateTerritories();
        } catch (error) {
            console.error('Error Initialization', error);
        }
    }

    async initGroupsInfo() {
        try {
            this.groups = await this.restService.getAllGroups();
        } catch (error) {
            console.error('Error initGroupsInfo', error);
            throw error;
        }
    }

    async initTerritoryScales() {
        try {
            await this.setTerritoryScales();
            this.territoryScales = this.terService.territoryScales;
        } catch (error) {
            console.error('Error initTerritoryScales', error);
            throw error;
        }
    }

    async setTerritoryScales() {
        if (!this.terService.territoryScales) {
            await this.terService.initTerritory(false, false);
        }
    }

    // ##################################### General Info ##########################################################
    initGeneralInfo() {
        this.generalInfoManagement = {
            isEditing: false,
            isSaving: false,
            isDisabled: false,
        };

        this.generalInfo = { ...this.user.generalInfo };
        this.generalInfoTmp = { ...this.user.generalInfo };
    }

    isGeneralInfoFormCompleted() {
        return !Object.values(this.generalInfoTmp).includes(undefined);
    }

    cancelGeneralInfo() {
        this.generalInfoManagement.isEditing = false;
        this.generalInfoManagement.isSaving = false;
        this.initGeneralInfo();
    }

    async validateGeneralInfo() {
        this.generalInfoManagement.isSaving = true;

        const data = { generalInfo: this.generalInfoTmp };
        try {
            this.user = await this.restService.updateUser(this.userId, data);
            this.initGeneralInfo();

            this.generalInfoManagement.isEditing = false;
            this.generalInfoManagement.isSaving = false;

            const surname = this.user.generalInfo.surname;
            const name = this.user.generalInfo.name;
            this.notification.success(`L'utilisateur "${surname} ${name}" a été mis à jour.`);
        } catch (error) {
            console.error('Error validateGeneralInfo', error);
            this.generalInfoManagement.isEditing = true;
            this.generalInfoManagement.isSaving = false;
            this.notification.error('Une erreur est survenue, veuillez rééssayer.');
        }
    }

    // ##################################### Territories ##########################################################
    initTerritory() {
        this.territoryManagement = {
            isEditing: false,
            isSaving: false,
            isDisabled: false,
        };

        const territoryScaleType = this.user.territory.territoryScale.type;
        const granularityType = this.user.territory.granularity.type;

        this.selectedTerritoryScale = this.territoryScales.find(
            (territoryScale: TerritoryScale) =>
                territoryScale.geom_poly && territoryScale.type == territoryScaleType,
        );

        this.selectedTerritories = this.user.territory.territories;

        this.selectedGranularity = this.territoryScales.find(
            (territoryScale: TerritoryScale) =>
                territoryScale.geom_poly && territoryScale.type == granularityType,
        );
        this.updateGranularities();

        this.territoryLabels = this.selectedTerritories
            .map((t: Territory) => `${t.label}`)
            .join(', ');
    }

    async selectTerritoryScale() {
        this.updateGranularities();
        try {
            await this.updateTerritories();
            this.selectedTerritories = [];
            this.territoryLabels = '';
        } catch (error) {
            console.error('Error selectTerritoryScale', error);
            throw error;
        }
    }

    async updateTerritories() {
        try {
            this.territories = await this.terService.getTerritoryLabelByYear(
                this.selectedTerritoryScale.year,
                this.selectedTerritoryScale.type,
            );
            // this.selectedTerritories = [];
            // this.territoryLabels = '';
            // this.territoryLabels = this.selectedTerritories
            //     .map((t: Territory) => `${t.label}`)
            //     .join(', ');
        } catch (error) {
            console.error('Error updateTerritories', error);
            throw error;
        }
    }

    updateGranularities() {
        if (this.selectedTerritoryScale) {
            this.granularities = this.territoryScales.filter(
                (territoryScale: TerritoryScale) =>
                    territoryScale.ordre < this.selectedTerritoryScale.ordre &&
                    territoryScale.geom_poly,
            );
        } else {
            this.granularities = this.territoryScales.filter(
                (territoryScale: TerritoryScale) => territoryScale.geom_poly,
            );
        }
        if (!this.granularities.includes(this.selectedGranularity)) {
            this.selectedGranularity = this.granularities[0];
        }

        if (this.selectedGranularity) {
            let currentGranularityType = this.selectedGranularity.type;
            currentGranularityType =
                currentGranularityType[0] == '0'
                    ? currentGranularityType.slice(1)
                    : currentGranularityType;
            let newTerritoryScaleType = this.selectedTerritoryScale.type;
            newTerritoryScaleType =
                newTerritoryScaleType[0] == '0'
                    ? newTerritoryScaleType.slice(1)
                    : newTerritoryScaleType;

            if (newTerritoryScaleType < currentGranularityType) {
                this.selectedGranularity = null;
            }
        }
    }

    removeTerritory = (index: number) => {
        this.selectedTerritories.splice(index, 1);
    };

    addTerritory = (territoryToAdd: TerritoryLabel) => {
        const alreadyAddedTerritory = this.selectedTerritories.find(
            (territory: any) => territory.id == territoryToAdd.id,
        );

        if (!alreadyAddedTerritory) {
            const id = territoryToAdd.id;
            const label = territoryToAdd.label;
            const newSelectedTerritory = {
                id: id,
                label: label,
                labelId: `${label} (${id})`,
            };
            this.selectedTerritories.push(newSelectedTerritory);
        }
    };

    setTerritoryLabels() {
        return this.selectedTerritories.map((t: Territory) => `${t.label}`).join(', ');
    }

    cancelTerritory() {
        this.territoryManagement.isEditing = false;
        this.territoryManagement.isSaving = false;
        this.initTerritory();
    }

    async validateTerritory() {
        this.territoryManagement.isSaving = true;

        const territory = {
            territoryScale: {
                type: this.selectedTerritoryScale.type,
                labelLong: this.selectedTerritoryScale.libelle_long,
                labelShort: this.selectedTerritoryScale.libelle_short,
            },
            territories: this.selectedTerritories,
            granularity: {
                type: this.selectedGranularity.type,
                labelLong: this.selectedGranularity.libelle_long,
                labelShort: this.selectedGranularity.libelle_short,
            },
        };

        try {
            this.user = await this.restService.updateUser(this.userId, {
                territory: territory,
            });
            this.initTerritory();

            this.territoryManagement.isEditing = false;
            this.territoryManagement.isSaving = false;

            const surname = this.user.generalInfo.surname;
            const name = this.user.generalInfo.name;
            this.notification.success(`L'utilisateur "${surname} ${name}" a été mis à jour.`);
        } catch (error: any) {
            console.error('Error validateTerritory', error);
            this.territoryManagement.isEditing = true;
            this.territoryManagement.isSaving = false;
            this.notification.error('Une erreur est survenue, veuillez rééssayer.');
        }
    }

    // ##################################### Role & group ##########################################################
    initRoleGroup() {
        this.roleGroupManagement = {
            isEditing: false,
            isSaving: false,
            isDisabled: false,
        };

        this.initRole();
        this.initGroup();
    }

    initRole() {
        if (!this.isUserSuperAdmin) {
            this.rolesOption = this.roles.filter((role) => role.id != ROLE.SUPER_ADMIN);
        }
        this.selectedRole = this.roles.find((role: any) => role.id == this.user.roleId);
    }

    async selectRole() {
        this.selectedTerritoryScale = null;
        this.selectedTerritories = [];
        this.selectedGranularity = null;

        let territoryScaleType: string;
        let territories: Territory[];
        let granularityType: string;

        if (this.selectedRole.id === 1) {
            territoryScaleType = 'ter_99';
            territories = [
                {
                    id: '1',
                    label: 'France',
                },
            ];
            granularityType = 'ter_01';
        } else {
            territoryScaleType = this.selectedGroup.territory.territoryScale.type;
            territories = this.selectedGroup.territory.territories;
            granularityType = this.selectedGroup.territory.granularity.type;
        }

        const territoryScale = this.territoryScales.find(
            (territoryScale: TerritoryScale) => territoryScale.type === territoryScaleType,
        );

        const granularity = this.territoryScales.find(
            (territoryScale: TerritoryScale) => territoryScale.type === granularityType,
        );

        this.selectedTerritoryScale = territoryScale;
        try {
            await this.selectTerritoryScale();
            this.selectedTerritories = territories;
            this.selectedGranularity = granularity;

            this.territoryLabels = this.selectedTerritories
                .map((t: Territory) => `${t.label}`)
                .join(', ');

            this.initTerritory();
            if (this.selectedRole.id == 3) {
                this.initIndicators();
            }
        } catch (error) {
            console.error('Error selectRole', error);
            throw error;
        }
    }

    initGroup() {
        this.selectedGroup = this.groups.find((group: any) => group.id == this.user.groupId);

        const territoryScaleType = this.selectedGroup.territory.territoryScale.type;
        const granularityType = this.selectedGroup.territory.granularity.type;

        this.selectedTerritoryScale = this.terService.territoryScales.find(
            (t: TerritoryScale) => t.type == territoryScaleType,
        );
        this.selectedTerritories = this.selectedGroup.territory.territories;
        this.selectedGranularity = this.terService.territoryScales.find(
            (t: TerritoryScale) => t.type == granularityType,
        );

        this.territoryLabels = this.selectedTerritories
            .map((t: Territory) => `${t.label}`)
            .join(', ');
    }

    selectGroup() {
        const territoryScaleType = this.selectedGroup.territory.territoryScale.type;
        const granularityType = this.selectedGroup.territory.granularity.type;

        this.selectedTerritoryScale = this.terService.territoryScales.find(
            (t: TerritoryScale) => t.type == territoryScaleType,
        );
        this.selectedTerritories = this.selectedGroup.territory.territories;
        this.selectedGranularity = this.terService.territoryScales.find(
            (t: TerritoryScale) => t.type == granularityType,
        );

        this.territoryLabels = this.selectedTerritories
            .map((t: Territory) => `${t.label}`)
            .join(', ');

        if (this.selectedRole.id == 3) {
            this.initIndicators();
        }
    }

    cancelRoleGroup() {
        this.roleGroupManagement.isEditing = false;
        this.roleGroupManagement.isSaving = false;

        this.initRole();
        this.initGroup();
        this.initTerritory();
        this.initIndicators();
    }

    async validateRoleGroup() {
        this.roleGroupManagement.isSaving = true;

        this.selectGroup();

        const data = {
            roleId: this.selectedRole.id,
            groupId: this.selectedGroup.id,
            territory: this.getSelectedTerritoryData(),
            indicatorIds: undefined,
        };

        if (this.selectedRole.id == 3) {
            data.indicatorIds = this.getSelectedIndicatorIds();
        }

        try {
            this.user = await this.restService.updateUser(this.userId, data);

            this.initRoleGroup();
            this.initTerritory();
            this.initIndicators();

            this.roleGroupManagement.isEditing = false;
            this.roleGroupManagement.isSaving = false;

            const surname = this.user.generalInfo.surname;
            const name = this.user.generalInfo.name;
            this.notification.success(`L'utilisateur "${surname} ${name}" a été mis à jour.`);
        } catch (error) {
            console.error('Error validateRoleGroup', error);
            this.roleGroupManagement.isEditing = true;
            this.roleGroupManagement.isSaving = false;
            this.notification.error('Une erreur est survenue, veuillez rééssayer.');
        }
    }

    getSelectedTerritoryData() {
        const territory = {
            territoryScale: {
                type: this.selectedTerritoryScale.type,
                labelLong: this.selectedTerritoryScale.libelle_long,
                labelShort: this.selectedTerritoryScale.libelle_short,
            },
            territories: this.selectedTerritories,
            granularity: {
                type: this.selectedGranularity.type,
                labelLong: this.selectedGranularity.libelle_long,
                labelShort: this.selectedGranularity.libelle_short,
            },
        };
        return territory;
    }

    // ##################################### Indicators  ##########################################################
    async initIndicators() {
        this.indicatorsManagement = {
            isEditing: false,
            isSaving: false,
            isDisabled: false,
        };

        this.selectedIndicatorIds = this.user.indicatorIds;

        const parameters = {
            groupId: this.selectedGroup.id,
        };
        try {
            this.indicatorsByTheme = await this.restService.getIndicatorByModule(parameters);
            this.indicatorsByTheme.forEach((theme: Theme) => {
                theme.open = false;
                theme.ss_theme.forEach((subTheme: SubTheme) => {
                    subTheme.open = false;

                    subTheme.indicators.forEach(
                        (indicator: Indicator) => (indicator.active = false),
                    );

                    subTheme.indicators
                        .filter((indicator: Indicator) =>
                            this.selectedIndicatorIds.includes(indicator.id_indicateur),
                        )
                        .forEach((indicator: Indicator) => (indicator.active = true));
                });
            });

            this.updateSelectionState();
        } catch (error) {
            console.error('Error initIndicators', error);
            throw error;
        }
    }

    isIndicatorInGroup = (indicator: Indicator) => {
        return this.selectedGroup.indicators.includes(indicator.id_indicateur);
    };

    selectAllIndicatorFromTheme = (theme: Theme) => {
        if (this.indicatorsManagement.isEditing) {
            theme.ss_theme.forEach((subTheme: SubTheme) =>
                subTheme.indicators.forEach((indicator: Indicator) => {
                    indicator.active = theme.full < 2;
                    this.manageIndicator(indicator);
                }),
            );
            this.updateSelectionState();
        }
    };

    selectAllIndicatorFromSubTheme = (subTheme: SubTheme) => {
        if (this.indicatorsManagement.isEditing) {
            subTheme.indicators.forEach((indicator: Indicator) => {
                indicator.active = subTheme.full < 2;
                this.manageIndicator(indicator);
            });
            this.updateSelectionState();
        }
    };

    manageIndicator = (indicator: Indicator) => {
        const indicatorId = indicator.id_indicateur;
        if (this.selectedIndicatorIds.includes(indicatorId)) {
            const index = this.selectedIndicatorIds.indexOf(indicatorId);
            this.selectedIndicatorIds.splice(index, 1);
        } else {
            this.selectedIndicatorIds.push(indicatorId);
        }
    };

    updateSelectionState() {
        let p = 0;
        let d = 0;

        this.indicatorsByTheme.forEach((theme: Theme) => {
            d = 0;
            theme.ss_theme.forEach((subTheme: SubTheme) => {
                p = 0;
                p += subTheme.indicators.filter((indicator: Indicator) => indicator.active).length;

                if (p == subTheme.indicators.length) {
                    subTheme.full = 2;
                    d += 1;
                } else if (p > 0) {
                    subTheme.full = 1;
                    d += 0.5;
                } else {
                    subTheme.full = 0;
                }
            });

            if (d == theme.ss_theme.length) {
                theme.full = 2;
            } else if (d > 0) {
                theme.full = 1;
            } else {
                theme.full = 0;
            }
        });
    }

    cancelIndicators() {
        this.indicatorsManagement.isEditing = false;
        this.indicatorsManagement.isSaving = false;
        this.initIndicators();
    }

    async validateIndicators() {
        this.indicatorsManagement.isEditing = true;

        const data = {
            roleId: this.selectedRole.id,
            indicatorIds: this.getSelectedIndicatorIds(),
        };
        try {
            this.user = await this.restService.updateUser(this.userId, data);
            this.indicatorsManagement.isEditing = false;
            this.indicatorsManagement.isSaving = false;

            const surname = this.user.generalInfo.surname;
            const name = this.user.generalInfo.name;
            this.notification.success(`L'utilisateur "${surname} ${name}" a été mis à jour.`);
        } catch (error) {
            console.error('Error validateIndicators', error);
            this.indicatorsManagement.isEditing = true;
            this.indicatorsManagement.isSaving = false;
            this.notification.error('Une erreur est survenue, veuillez rééssayer.');
        }
    }

    getSelectedIndicatorIds() {
        let selectedindicatorIds = [];
        this.indicatorsByTheme.forEach((theme: Theme) => {
            theme.ss_theme.forEach((subTheme: SubTheme) => {
                const newIndicatorIds = subTheme.indicators
                    .filter((indicator: Indicator) => indicator.active)
                    .map((indicator: Indicator) => indicator.id_indicateur);
                selectedindicatorIds.push(...newIndicatorIds);
            });
        });
        return selectedindicatorIds;
    }

    // ##################################### Main Initialization ########################################################

    redirectToAdminUsers() {
        this.router.navigate(['administration/users/manage/']);
    }

    public changeActiveIndicator = (indicator) => {};
}
