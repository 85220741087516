<stHeader></stHeader>
<stNavbar data-page="users-manage"></stNavbar>

<div id="user-add" class="container-fluid py-4">
    <h3 class="text-st-secondary my-5">
        <span class="fa-stack fa-lg text-st-secondary">
            <i class="fa fa-circle-thin fa-stack-2x"></i>
            <i class="fa fa-users fa-stack-1x"></i>
        </span>
        Nouvel utilisateur
    </h3>

    <hr class="separator" />

    <div id="general-info" class="container-fluid">
        <h4 class="mt-0">
            <b>Informations personnelles</b>
        </h4>

        <div class="container-fluid">
            <form name="_nomFG" novalidate>
                <div class="row mb-3">
                    <label class="col-3 control-label" for="user-surname">
                        Prénom<sup>*</sup> :
                    </label>
                    <div class="col-3">
                        <stInput
                            innerId="user-surname"
                            type="text"
                            name="_nom"
                            [(ngModel)]="newUser.surname"
                            [isRequired]="true"
                            [ngModelOptions]="{standalone: true}"
                        >
                        </stInput>
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="col-3 control-label" for="user-lastname">
                        Nom<sup>*</sup> :
                    </label>
                    <div class="col-3">
                        <stInput
                            innerId="user-lastname"
                            type="text"
                            [(ngModel)]="newUser.name"
                            [isRequired]="true"
                            [ngModelOptions]="{standalone: true}"
                        ></stInput>
                    </div>
                </div>
                <div class="row mb-3" name="_mailFG">
                    <label class="col-3 control-label" for="user-mail">
                        Adresse mail<sup>*</sup> :
                    </label>
                    <div class="col-3">
                        <stInput
                            innerId="user-mail"
                            type="mail"
                            name="_mailFG"
                            [(ngModel)]="newUser.mail"
                            (ngFocus)="mailCliked = false"
                            (ngBlur)="mailCliked = true"
                            [ngClass]="{inputError: _mailFG?._mail.error?.required && mailCliked }"
                            [isRequired]="true"
                            [ngModelOptions]="{standalone: true}"
                        ></stInput>
                        <span
                            role="alert"
                            class="text-st-danger"
                            *ngIf="_mailFG?._mail.error?.email && mailCliked"
                        >
                            L'adresse email n'est pas valide : renseigner une adresse de type
                            "alain.dupond@gmail.com"
                        </span>
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="col-3 control-label" for="user-login">
                        Identifiant de connexion<sup>*</sup> :
                    </label>
                    <div class="col-3">
                        <stInput
                            innerId="user-login"
                            type="text"
                            [(ngModel)]="newUser.login"
                            [isRequired]="true"
                            [ngModelOptions]="{standalone: true}"
                        ></stInput>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <hr class="separator" />

    <div id="role-group" class="container-fluid">
        <h4 class="mt-0">
            <b>Rôle et groupe</b>
        </h4>

        <div class="container-fluid">
            <form novalidate>
                <div class="row d-flex align-items-center mb-3">
                    <label class="col-3 control-label py-0" for="role"> Rôle : </label>
                    <div class="col-3">
                        <div class="w-100">
                            <stSelect
                                innerId="role"
                                [(ngModel)]="selectedRole"
                                (change)="selectRole()"
                                [data]="roles"
                                valueField="fullObject"
                                labelField="label"
                                type="primary"
                                [ngModelOptions]="{standalone: true}"
                                name="role"
                                [isDisabled]="!groups"
                            >
                            </stSelect>
                        </div>
                    </div>
                    <div class="col-1">
                        <span
                            *ngIf="!selectedRole?.id"
                            class="fa fa-exclamation-circle text-st-danger"
                        ></span>
                        <span
                            *ngIf="selectedRole?.id && !!selectedGroup"
                            class="fa fa-check text-st-success"
                        ></span>
                    </div>
                </div>

                <div
                    class="row d-flex align-items-center mb-3"
                    *ngIf="isUserSuperAdmin && selectedRole?.id !== 1 && !!selectedGroup"
                >
                    <label class="col-3 control-label py-0" for="group"> Groupe : </label>
                    <div class="col-3">
                        <div class="w-100" *ngIf="selectedGroup">
                            <stSelect
                                innerId="group"
                                [(ngModel)]="selectedGroup"
                                (change)="selectGroup()"
                                [data]="groups"
                                labelField="group.nom"
                                valueField="fullObject"
                                type="primary"
                                [ngModelOptions]="{standalone: true}"
                                [isDisabled]="!selectedGroup"
                            >
                            </stSelect>
                        </div>
                    </div>
                    <div class="col-1">
                        <span
                            *ngIf="!(selectedGroup?.id >= 0) "
                            class="fa fa-exclamation-circle text-st-danger"
                        ></span>
                        <span
                            *ngIf="selectedGroup?.id >= 0 && selectedGroup?.id!=undefined"
                            class="fa fa-check text-st-success"
                        ></span>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <hr class="separator" *ngIf="selectedRole?.id === 3" />

    <div id="territory-rights" class="container-fluid" *ngIf="selectedRole?.id === 3">
        <h4 class="d-inline-block mt-0">
            <b>Droits sur les territoires</b>
        </h4>

        <div class="container-fluid">
            <div id="geographical-boundaries">
                <h5>
                    <b>Périmètre géographique</b>
                </h5>

                <form novalidate class="container-fluid">
                    <div class="row d-flex align-items-center mb-3">
                        <label class="col-3 control-label" for="territory-scale">
                            Echelle de territoire :
                        </label>
                        <div class="col-3">
                            <stSelect
                                innerId="territory-scale"
                                [(ngModel)]="selectedTerritoryScale"
                                (change)="selectTerritoryScale()"
                                [data]="territoryScales"
                                order-by="ordre"
                                labelField="libelle_short"
                                valueField="fullObject"
                                type="primary"
                                name="territory"
                            >
                            </stSelect>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center mb-3">
                        <label for="territory" class="col-3 control-label py-0">
                            Territoire<sup>*</sup> :
                        </label>
                        <div class="col-3">
                            <stInputSearch
                                class="dropdown btn-block"
                                innerId="territory"
                                innerClass="border-07"
                                label="labelId"
                                placeholder="Saisir le nom ou code INSEE"
                                [options]="territories"
                                (onSelect)="addTerritory($event)"
                                *ngIf="territories.length > 0"
                                [isDisabled]="!selectedTerritoryScale.id"
                            >
                            </stInputSearch>
                            <i class="invalid-feedback" *ngIf="!selectedTerritories.length">
                                Au moins un territoire est requis.
                            </i>
                        </div>
                    </div>
                    <div class="selected-territory">
                        <div
                            class="btn-group my-3 me-3"
                            *ngFor="let territory of selectedTerritories; let idx = index;"
                        >
                            <stButton
                                btnLabel="{{ territory.label }}"
                                btnClass="btn-st-default d-flex align-items-center"
                                iconRight="fa fa-times ps-2"
                                (click)="removeTerritory(idx)"
                            >
                            </stButton>
                        </div>
                    </div>
                </form>
            </div>

            <div id="geo-granularity">
                <h5>
                    <b>Découpage géographique</b>
                </h5>

                <form class="container-fluid" novalidate *ngIf="!!selectedGranularity">
                    <div class="row d-flex align-items-center">
                        <label class="col-3 control-label py-0" for="granularity">
                            Granularité :
                        </label>
                        <div class="col-3">
                            <stSelect
                                innerId="granularity"
                                [(ngModel)]="selectedGranularity"
                                [data]="granularities"
                                order-by="ordre"
                                labelField="libelle_short"
                                valueField="fullObject"
                                type="primary"
                                name="granularity"
                            >
                            </stSelect>
                        </div>
                        <div class="col-1">
                            <span
                                *ngIf="!selectedGranularity.id"
                                class="fa fa-exclamation-circle text-st-danger"
                            ></span>
                            <span
                                *ngIf="selectedGranularity.id"
                                class="fa fa-check text-st-success"
                            ></span>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <hr class="separator" *ngIf="selectedRole?.id === 3" />

    <div id="indicator-rights" class="container-fluid" *ngIf="selectedRole?.id === 3">
        <h4 class="mt-0">
            <b>Droits sur les indicateurs</b>
        </h4>

        <div
            class="container-fluid mb-3"
            *ngFor="let theme of indicatorsByTheme | orderBy : 'libelle'"
        >
            <span class="cursor-pointer" (click)="theme.open = !theme.open">
                <i class="fa fa-caret-down iw-4" *ngIf="theme.open"></i>
                <i class="fa fa-caret-right iw-4" *ngIf="!theme.open"></i>
                <span class="text-st-secondary fs-6">
                    <b>{{ theme.libelle }}</b>
                </span>
            </span>

            <span class="cursor-pointer mx-4" (click)="selectAllIndicatorFromTheme(theme)">
                <span *ngIf="theme.full == 2" class="fa fa-star"></span>
                <span *ngIf="theme.full == 1" class="fa fa-star-half-o"></span>
                <span *ngIf="theme.full == 0" class="fa fa-star-o"></span>
            </span>

            <ng-container *ngFor="let ss_theme of theme.ss_theme | orderBy: 'ordre'">
                <div class="container-fluid mb-2" *ngIf="theme.open">
                    <span class="cursor-pointer" (click)="ss_theme.open = !ss_theme.open">
                        <i class="fa fa-caret-down iw-4" *ngIf="ss_theme.open"></i>
                        <i class="fa fa-caret-right iw-4" *ngIf="!ss_theme.open"></i>
                        <span>
                            <b>{{ ss_theme.libelle }}</b>
                        </span>
                    </span>

                    <span
                        class="cursor-pointer mx-4"
                        (click)="selectAllIndicatorFromSubTheme(ss_theme)"
                    >
                        <span *ngIf="ss_theme.full == 2" class="fa fa-star"></span>
                        <span *ngIf="ss_theme.full == 1" class="fa fa-star-half-o"></span>
                        <span *ngIf="ss_theme.full == 0" class="fa fa-star-o"></span>
                    </span>

                    <div class="container-fluid px-5" *ngIf="ss_theme.open">
                        <div
                            class=""
                            *ngFor="let indicator of ss_theme.indicators | sortBy: 'ordre'"
                            (click)="changeActiveIndicator(indicator)"
                        >
                            <stCheckbox
                                innerId="indicator-{{ indicator.id_indicateur }}"
                                [(ngModel)]="indicator.active"
                                (change)="manageIndicator(indicator)"
                            >
                                {{ indicator.libelle_indic_short }}
                                <span *ngIf="indicator.unit && indicator.unit !== '%'">
                                    - {{ indicator.unit }}
                                </span>
                            </stCheckbox>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

    <hr class="separator" />

    <div id="validate-add-user" class="container-fluid">
        <h4 class="mt-0">
            <b>Terminer la création du nouvel utilisateur</b>
        </h4>
        <div class="d-flex align-items-center">
            <stButton
                btnLabel="Annuler"
                btnClass="btn-st-default mx-3"
                (click)="redirectToAdminUsers()"
                [isDisabled]="isValidating"
            >
            </stButton>
            <stButton
                btnLabel="Valider"
                btnClass="btn-st-primary mx-3"
                (click)="validate()"
                [isDisabled]="!(selectedGroup?.id >=0 || selectedRole?.id == 1)
                || !selectedRole?.id
                || !newUser.surname
                || !newUser.name
                || !newUser.mail
                || !newUser.mail
                || _mailFG?._mail.error?.email
                || !newUser.login
                || isValidating"
            >
            </stButton>
            <i
                class="fa fa-spinner fa-pulse fa-2x fa-fw mx-3 text-st-active"
                *ngIf="isValidating"
            ></i>
        </div>
    </div>
</div>
