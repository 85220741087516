<div id="resetPasswordModal" tabindex="-1" aria-labelledby="resetPasswordModal" aria-modal="true">
    <div class="modal-header">
        <h4 class="modal-title">Changement de mot de passe</h4>
    </div>
    <div class="modal-body">
        <form novalidate class="container-fluid">
            <div class="row d-flex align-items-center mb-3">
                <label for="new-password" class="col-sm-4 text-end">
                    Nouveau mot de passe* :
                </label>
                <div class="col-sm-8">
                    <stInput
                        type="password"
                        innerId="new-password"
                        [(ngModel)]="password"
                        name="new-password"
                    ></stInput>
                </div>
            </div>
            <div class="row d-flex align-items-center">
                <label for="new-confirmed-password" class="col-sm-4 text-end">
                    Confirmation du mot de passe* :
                </label>
                <div class="col-sm-8">
                    <stInput
                        type="password"
                        innerId="new-confirmed-password"
                        [(ngModel)]="confirmedPassword"
                        name="new-confirmed-password"
                    ></stInput>
                </div>
            </div>
        </form>
    </div>

    <div class="modal-footer">
        <stButton
            btnLabel="Annuler"
            btnClass="btn-st-default me-1"
            (onClick)="activeModal.dismiss();"
        >
        </stButton>
        <stButton
            btnLabel="Valider"
            btnClass="btn-st-primary ms-1"
            [isDisabled]="!password || password !== confirmedPassword"
            (onClick)="validate();"
        >
        </stButton>
    </div>
</div>
